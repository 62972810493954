import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';
import { getRequest, showMessage } from "../../../config.js";
import { toggleModal } from "../../../reducers/modalReducer.js";

export default function Workflows({ company, setTab, setIsWorkflowReport, setCompanyDashboardTab, }) {

	const dispatch = useDispatch();
	const navigation = useNavigate();

	const user = useSelector(state => state.user);
	const modal = useSelector(state => state.modal);

	let [selectedFeature, setSelectedFeature] = useState({});

	const navigateSection = async (tab, sectionNo) => {
		await setTab(tab);
		setTimeout(() => {
			let element = document.getElementById(sectionNo);
			if (element) element.scrollIntoView({ behavior: 'instant' }, true);
		}, 1000);
	};

	const redirectWorkflowSection = async (section) => {
		await setIsWorkflowReport(true);
		await navigateSection(`${section['moduleName']}-report`, section['sectionNo']);
		setCompanyDashboardTab('Main Dashboard');
	};


	const redirectWorkflowTab = async (moduleName) => {
		await setIsWorkflowReport(true);
		await setTab(`${moduleName}-report`);
		setCompanyDashboardTab('Main Dashboard');
	};

	const checklistModules = (modules) => {
		return Object.values(modules).map(module => {
			let isRedirect = false;
			if (module?.['stats'].completionPercent > 0) isRedirect = true;

			return <div className={`bo7 w25 keyfeature-wihte-item bg1 new-shadow br15px p2rem mx1rem my1-5rem pr bsbb`}>
				<Link className="ttc fs1rem fw500 c15 lh2 zi1 w100">
					<Link to={isRedirect && `#${module['type']}-report`} onClick={() => { isRedirect ? redirectWorkflowTab(module['type']) : showMessage('Module not started yet!') }} class="cp w100 h100" style={{ minHeight: '130px' }}>
						<div className='w100 df aic'>
							<i className='icon-diligenstia mr1rem'>
								<img className="br5px" src={module?.['imageURL'] || require(`../../../../images/checklist-logo.png`)} alt="" />
							</i>
							<button className='cp fs1rem fw500 bg1 c4-hover c15 lh2 title-box-feature tal' style={{ background: 'transparent' }}>{module['type']}</button>
						</div>
						<div className="w100 df jcsb">
							<p className="c12 fw300 fs0-75rem mt4rem">{(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']) ? moment(module?.['createdAt'] || module?.['sections']?.[0]?.['createdAt']).format('L') : '-'}</p>
							<div className="df fdc jcc aic">
								<div className={`progress-bar workflow-report-progress ${module?.stats?.workflowCompletionPercent > 75 ? 'workflow-progress-green' : module?.stats?.workflowCompletionPercent > 26 ? 'workflow-progress-yellow' : module?.stats?.workflowCompletionPercent > 1 ? ' workflow-progress-pink' : '  workflow-progress-grey'}`}
									style={{ width: 50, height: 50, borderRadius: '50%', margin: 0, border: '2px solid #F1F1F1' }}>
									<CircularProgressbar
										text={module?.stats?.workflowCompletionPercent ? (module?.stats?.workflowCompletionPercent) + "%" : 'N/A'}
										styles={buildStyles({
											strokeWidth: '0',
											textSize: '20px',
											trailColor: '#FBFBFB',
											stroke: 'transparent',
											strokeLinecap: 'butt',
											backgroundColor: '#FBFBFB',
											pathTransitionDuration: 0.5,
											dominantBaseline: 'ideographic',
										})}
									/>
								</div>
								<span style={{ marginBottom: "0", }} className="tac c15 fs0-75rem fw300">Workflow <br /> Completeness</span>
							</div>
						</div>

					</Link>
				</Link>
			</div>
		});
	};

	return (
		<>
			<div className='sectionWrapper py1rem fs0-75rem pr anylist-builder-tab'>
				<div className='mt1rem bss px1rem py2rem br25px w100 keyfeature-box-portfolio anylist-checklist df fww mb3rem pr bsbb'>
					<h3 className="fs1-25rem c15 w100 pl1rem mb2rem">Workflows</h3>
					{/* Checklist Modules */}
					{(company?.['modules'] && Object.values(company['modules']).length > 0) && checklistModules(Object.values(company['modules']).filter(item => item['category'] == 'all'))}
				</div>
			</div>
		</>
	)
}