import React from 'react'
import useAdCardData from '../../../hooks/useAdCardData'

const UserAdCard = ({ onCardLog, LiButton, user, company, truncateText, getSectionName, visibleSection, getCompanyById }) => {

    const { data, adId, loading } = useAdCardData(visibleSection)

    if (data && Object.keys(data).length) {
        return (
            <>
                <div className="user-info-sidebar rj mt12 cp df jcc py0-5rem px0-5rem aic fdc bw0px btw2px pa b0 bg5" style={{ width: "94%", paddingLeft: '0.5rem' }}>
                    <div className="bs1 bo2 bss bw2px br8px px0-5rem">
                        <div className="df w100 jcsb mt0-5rem">
                            <div className="df fdc w70">
                                <div className="df pr">
                                    {/* {
                                        data?.isDefault ? <div
                                            className='pa'
                                            style={{ left: '-2px', top: '-2px' }}
                                        >
                                            <img
                                                className="br100"
                                                style={{ width: '16px', height: '16px' }}
                                                src={require('../../../../images/star.png')}
                                                alt="star-Image"
                                            />
                                        </div> : null
                                    } */}

                                    <img
                                        className="br100"
                                        style={{ width: '40px', height: '40px' }}
                                        src={data?.profile ? data?.profile : require('../../../../images/profile-photo.png')}
                                        alt="alloctaed-Advisor-Image"
                                    />
                                    {
                                        (data && data.linkedIn) && <div className="pt0-5rem pb0-5rem df fdc ml0-5rem jcc" style={{ marginBottom: "7px" }}>
                                            <LiButton link={data.linkedIn} withoutLog={true} />
                                        </div>
                                    }

                                </div>
                                <div onClick={() => onCardLog(company, user, data, 'title', { visibleSection, adId, isDefault: data?.isDefault })} className="w90 df fdc">
                                    <div className="mt0-5rem fw500" style={{ fontSize: '11px', textTransform: 'capitalize' }}>
                                        {`${data?.firstName ?? "Ted"} ${data?.lastName ?? "Bechman"}`}
                                    </div>
                                    <div className="fw500" style={{ fontSize: '9px', textTransform: 'capitalize' }}>Chief Executive Officer</div>
                                </div>
                            </div>
                            {
                                data?.company['logo'] && <img className="bg1 br100 ofcov" style={{ width: '44px', height: '40px' }}
                                    src={data?.company['logo']}
                                    alt="company_logo"
                                    onClick={() => getCompanyById(typeof data?.companyId === "string" ? data?.companyId : data?.companyId?._id)}
                                />
                            }

                        </div>
                        <div className="fw500 px0-5rem mt0-5rem mb0-5rem" style={{ paddingLeft: '0px', fontSize: '11px', width: "100%" }}>
                            <div className="fw500" style={{ fontSize: '11px', width: "100%" }}>
                                <p style={{ fontSize: '10px' }}>
                                    {truncateText(`Need to chat about your ${getSectionName(company, visibleSection)} requirements?  No obligation call...`, 100)}
                                </p>

                                {
                                    data?.phone ? <p className="mt0-5rem" onClick={() => onCardLog(company, user, data, 'phoneNumber', { visibleSection, adId })}>
                                        <span className="fw300">Tel:</span>{" "}
                                        <a
                                            href={`tel:${data?.phone}`} >
                                            {data?.phone}
                                        </a>
                                    </p> : null
                                }
                                <p onClick={() => onCardLog(company, user, data, "email", { visibleSection, adId })}>
                                    <span className="fw300">Email:</span>{" "}
                                    <a href={`mailto:${data?.email ?? "ted@boardpieces.com"}`}
                                    >
                                        {truncateText(data?.email, 30) ?? "ted@boardpieces.com"}
                                    </a>
                                </p>
                            </div>
                        </div >
                    </div>
                </div>
            </>
        )
    } else {
        return (<></>)
    }
}

export default UserAdCard